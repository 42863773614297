<template>
  <div>
    <table-list
      ref="tableList"
      :fields="fields"
      module="messages"
      :show-search="true"
      :sort-by="sortBy"
      sidebar-title="Add new employee"
    />
  </div>
</template>

<script>
import {
  BMedia,
} from 'bootstrap-vue'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import messageStoreModule from './messageStoreModule'

export default {
  components: {
    TableList,
  },
  data() {
    return {
    }
  },
  setup() {
    const MESSAGE_STORE_MODULE_NAME = 'messages'

    // Register module
    if (!store.hasModule(MESSAGE_STORE_MODULE_NAME)) store.registerModule(MESSAGE_STORE_MODULE_NAME, messageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MESSAGE_STORE_MODULE_NAME)) store.unregisterModule(MESSAGE_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.messages.tableFields)
    const sortBy = computed(() => store.state.messages.sortBy)

    return {
      fields,
      sortBy,
    }
  },
  methods: {
    loadData(id) {
      this.$refs.tableList.$refs.tableListSidebar.loadData(id)
    },
  },
}
</script>

<style>

</style>
