import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'content', sortable: true },
    ],
    sortBy: null,
  },
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/messages', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetch(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/messages/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    create(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/messages', queryParams)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
